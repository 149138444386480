import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { CoreMarketplaceActionErrorFallback } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionErrorFallback.react"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  CancelSwapsActionModalQuery,
  CancelSwapsActionModalQuery$variables,
} from "@/lib/graphql/__generated__/CancelSwapsActionModalQuery.graphql"

export type CancelSwapsActionModalProps = Omit<
  BaseBlockchainActionProps,
  "title"
> &
  CancelSwapsActionModalQuery$variables

const BaseCancelSwapsActionModal = ({
  onEnd,
  onError,
  ...variables
}: CancelSwapsActionModalProps) => {
  const data = useLazyLoadQuery<CancelSwapsActionModalQuery>(
    graphql`
      query CancelSwapsActionModalQuery($swaps: [SwapRelayID!]!) {
        blockchain {
          bulkCancelSwaps(swaps: $swaps) {
            actions {
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    variables,
    { fetchPolicy: "network-only" },
  )

  return (
    <BlockchainActionList
      dataKey={data.blockchain.bulkCancelSwaps.actions}
      onEnd={onEnd}
      onError={onError}
    />
  )
}

const CancelSwapsActionModalWithSuspense = (
  props: CancelSwapsActionModalProps,
) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={props => {
        return (
          <CoreMarketplaceActionErrorFallback
            errorTags={errorTags}
            trackServerError={trackServerError}
            {...props}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <BaseCancelSwapsActionModal {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export const CancelSwapsActionModal = (props: CancelSwapsActionModalProps) => {
  return (
    <CoreMarketplaceActionTrackingContextProvider action="BulkDealCancel">
      <CancelSwapsActionModalWithSuspense {...props} />
    </CoreMarketplaceActionTrackingContextProvider>
  )
}
