import { CreateDealPageStep } from "@/features/deals/components/CreateDealPage"
import { DealStatus, DealViewer } from "@/features/deals/types"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

export type CreateDealProperties = {
  step: CreateDealPageStep
  makerAddress: string
  takerAddress: string
  makerAssetCount: number
  takerAssetCount: number
  chain?: string
  makerPaymentTokenSymbol?: string
  takerPaymentTokenSymbol?: string
}

export type ViewDealProperties = {
  dealRelayId: string
  dealStatus: DealStatus
  viewer: DealViewer
}

export type ConnectWalletSource = "create deal page" | "review deal page"

type ConnectWalletProperties = {
  source: ConnectWalletSource
}

export const useTrackCreateDealBackClick = () =>
  useTrackingFn<CreateDealProperties>("create deal back click")

export const useTrackCreateDealNextClick = () =>
  useTrackingFn<CreateDealProperties>("create deal next click")

export const useTrackCreateDealSendClick = () =>
  useTrackingFn<CreateDealProperties>("create deal send click")

export const useTrackCreateDealCompleted = () =>
  useTrackingFn<CreateDealProperties>("create deal completed")

export const useTrackCreateDealAddPaymentToken = () =>
  useTrackingFn<CreateDealProperties>("create deal add payment token")

export const useTrackCreateDealRemovePaymentToken = () =>
  useTrackingFn<CreateDealProperties>("create deal remove payment token")

export const useTrackCreateDealRefreshPaymentToken = () =>
  useTrackingFn<CreateDealProperties>("create deal add payment token")

export const useTrackViewDeal = () =>
  useTrackingFn<ViewDealProperties>("view deal")

export const useTrackViewDealCancelClick = () =>
  useTrackingFn<ViewDealProperties>("view deal cancel click")

export const useTrackDealCardCancelClick = () =>
  useTrackingFn<ViewDealProperties>("deal card cancel click")

export const useTrackDealCardCopyLinkClick = () =>
  useTrackingFn<ViewDealProperties>("deal card copy link click")

export const useTrackViewDealCancelCompleted = () =>
  useTrackingFn<ViewDealProperties>("view deal cancel completed")

export const useTrackViewDealAcceptClick = () =>
  useTrackingFn<ViewDealProperties>("view deal accept click")

export const useTrackSafelistedWarningDealModalAcceptDealClick = () =>
  useTrackingFn("safelisted warning modal accept deal clicked")

export const useTrackRoyaltyEnforcedDealModalAcceptDealClick = () =>
  useTrackingFn("royalty enforced warning modal accept deal clicked")

export const useTrackViewDealAcceptCompleted = () =>
  useTrackingFn<ViewDealProperties>("view deal accept completed")

export const useTrackViewDealShareClick = () =>
  useTrackingFn<ViewDealProperties>("view deal share click")

export const useTrackDealCardClick = () =>
  useTrackingFn<ViewDealProperties>("deal card click")

export const useTrackConnectWalletClick = () =>
  useTrackingFn<ConnectWalletProperties>("connect wallet click")
